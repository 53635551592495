var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[(_vm.title)?_c('h1',[_vm._v(" "+_vm._s(_vm.title)+" ")]):_vm._e(),(_vm.resultType || _vm.sortable)?_c('div',{staticClass:"d-flex justify-content-between mb-3",class:{'mt-3': _vm.marginTop}},[(_vm.filteredItems.length >= 0 && _vm.resultType)?[_c('div',{staticClass:"mr-2 p-2 results-page-head-element"},[_vm._v(" "+_vm._s(_vm.filteredItems.length)+" "+_vm._s(_vm.resultType)+" ")])]:_vm._e(),(_vm.sortable && _vm.filteredItems.length > 1)?[(_vm.md)?[_c('div',{staticClass:"p-2 results-page-head-element d-flex"},_vm._l((_vm.sortable),function(item,index){return _c('div',{key:index},[_c('div',{staticClass:"ml-2 mr-2"},[_c('a',{staticClass:"sort-button mr-2",class:{
                  'order-desc': _vm.sortingMetadata.key === item.key && _vm.sortingMetadata.desc,
                  'order-asc': _vm.sortingMetadata.key === item.key && !_vm.sortingMetadata.desc,
                  'ml-2': index > 0
                },on:{"click":function($event){return _vm.sort(_vm.filteredItems, item.key, item.isDate)}}},[_vm._v(" "+_vm._s(item.label)+" ")])])])}),0)]:[_c('b-dropdown',{attrs:{"id":"sort-dropdown","text":_vm.$t('actions.sort'),"size":"sm","right":""}},_vm._l((_vm.sortable),function(item,index){return _c('b-dropdown-item',{key:index,on:{"click":function($event){return _vm.sort(_vm.filteredItems, item.key)}}},[_c('a',{staticClass:"sort-button mr-2",class:{
                'order-desc': _vm.sortingMetadata.key === item.key && _vm.sortingMetadata.desc,
                'order-asc': _vm.sortingMetadata.key === item.key && !_vm.sortingMetadata.desc
              },on:{"click":function($event){return _vm.sort(_vm.filteredItems, item.key, item.isDate)}}},[_vm._v(" "+_vm._s(item.label)+" ")])])}),1)]]:_vm._e()],2):_vm._e(),(_vm.filter)?_c('div',{staticClass:"mb-3",class:{'mt-3': _vm.marginTop && !(_vm.resultType || _vm.sortable)}},[(_vm.filter && _vm.items.length > 1)?[_c('b-form-input',{attrs:{"placeholder":_vm.filterPlaceholder},on:{"input":_vm.filterResults}})]:_vm._e()],2):_vm._e(),_vm._t("addon"),_c('div',[_c('b-row',_vm._l((_vm.filteredItems.slice(_vm.perPage*(_vm.currentPage-1),_vm.perPage*(_vm.currentPage))),function(item,index){return _c('b-col',{key:index,class:_vm.maxPerColumn > 1 ? 'mb-4' : 'mb-3',attrs:{"cols":12 / _vm.perColumn}},[(_vm.searchResultDisplayType === 'searchResult')?_c('div',{staticClass:"d-flex h-100"},[_c('div',{staticClass:"w-100 p-3 search-result grey-box",class:{ 'search-result-hover': _vm.hover }},[_vm._t("default",null,{"item":item,"index":index})],2)]):_vm._t("default",null,{"item":item,"index":index})],2)}),1)],1),(_vm.pagination)?[_c('div',[(_vm.totalRows > _vm.perPage)?_c('b-pagination',{attrs:{"total-rows":_vm.totalRows,"per-page":_vm.perPage},on:{"input":function($event){return _vm.$emit('change', $event)}},model:{value:(_vm.currentPage),callback:function ($$v) {_vm.currentPage=$$v},expression:"currentPage"}}):_vm._e()],1)]:_vm._e()],2)}
var staticRenderFns = []

export { render, staticRenderFns }