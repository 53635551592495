









import Vue from 'vue'
import { Component } from 'vue-property-decorator'
import ShowMessages from '@/components/forms/ShowMessages.vue'
import { hasRight } from '@/utils/rights'
import Rights from '@/common/rights'
@Component({
  components: { ShowMessages }
})
export default class MessagingSentMessages extends Vue {
  get hasRightSend (): boolean {
    return hasRight(Rights.MESSAGE_WRITE)
  }
}
