




































































import Vue from 'vue'
import { Component, Prop } from 'vue-property-decorator'
import bootstrapBreakPoints from '@/common/breakpointsBootstrap'
import { State } from 'vuex-class'

@Component({
  components: {}
})
// The search result component is designed to be used inside of the default slot of the ResultsPage component.
// Goto there for more information and an example implementation.
// @group SEARCH RESULTS
export default class SearchResult extends Vue {
  @State('currentScreenWidth') screenWidth: number

  // How many results per column are displayed, must be the same in the surrounding ResultsPage component
  @Prop({ default: 1, validator: function (value) { return value >= 1 && value <= 4 } })
  maxPerColumn: number

  // Works only for 1 result per column (distribution 3-9, normal 2-10)
  // Normal distribution for 2 or 3 per column is also 4-8
  @Prop({ default: false })
  biggerSideElement: number

  // Put the side-element on top
  @Prop({ default: false })
  sideToTop: number

  // To configure the cursor pointer
  @Prop({ default: true })
  titleIsLink: boolean

  // No h3 for the title
  @Prop({ default: false })
  smallTitle: boolean

  get xl (): boolean {
    return this.screenWidth > bootstrapBreakPoints.xl
  }

  get bodyColumnCols (): number {
    if (this.hasSideElement) {
      if (this.sideToTop) return 12
      if (this.screenWidth <= 380) return 12
      if (this.maxPerColumn === 1) return this.biggerSideElement ? 9 : 10
      else return this.maxPerColumn === 4 && this.xl ? 12 : 8
    } else return 12
  }

  get hasSideElement (): boolean {
    return !!this.$slots.sideElement
  }

  onClick (): void {
    this.$emit('click')
  }
}
