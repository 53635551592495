
























































import Vue from 'vue'
import { Component, Prop } from 'vue-property-decorator'
import { Action } from 'vuex-class'
import SearchResult from '@/components/search-results/SearchResult.vue'
import ResultsPage from '@/components/search-results/ResultsPage.vue'
import { Messages } from '@/store/user/types'
import { GET_SENT_MESSAGES } from '@/store/employee/actions.type'
import { GET_MESSAGES } from '@/store/user/actions.type'
import { localFormat } from '@/common/datePicker'

@Component({
  components: { SearchResult, ResultsPage }
})
/*
This component contains the results page for messages
@group OTHER
 */
export default class ShowMessages extends Vue {
  @Prop({ required: true })
  hasRightSend: boolean

  @Action(GET_SENT_MESSAGES)
  public getSentMessages: () => Promise<Array<Messages>>

  @Action(GET_MESSAGES)
  public getMessages: () => Promise<Array<Messages>>

  messages: Array<Messages> = []

  parseDate (date: string): string {
    return localFormat(date, this.$i18n.locale, true)
  }

  loadMessages (): void {
    if (this.hasRightSend) {
      this.getSentMessages().then((data: Array<Messages>) => {
        this.messages = data.map((msg: Messages) => {
          return {
            ...msg,
            subtitle: this.$i18n.t('messaging.recipients.' + msg.recipientGroup).toString(),
            subject: msg.subject.toString(),
            content: msg.content.toString(),
            visible: msg.content.length < 133
          }
        })
      })
    } else {
      this.getMessages().then((data: Array<Messages>) => {
        this.messages = data.map((msg: Messages) => {
          return {
            ...msg,
            subtitle: msg.creatorFullName.toString() + msg.creatorFullName.toString() ? ', ' : '' + msg.creatorEmail.toString(),
            subject: msg.subject.toString(),
            content: msg.content.toString(),
            visible: msg.content.length < 133
          }
        })
      })
    }
  }

  mounted (): void {
    this.loadMessages()
  }
}
