





























































































































import Vue from 'vue'
import { Component, Prop, Watch } from 'vue-property-decorator'
import bootstrapBreakPoints from '@/common/breakpointsBootstrap'
import { State } from 'vuex-class'
import { sortArray } from '@/common/globals'
import { SortingInfo, FilteringType } from '@/store/commonFormInput/types'

@Component({
  components: {}
})
/*
@group SEARCH RESULTS
Important component which is a base frame to display all kinds of search results.
It is mostly used in combination with the SearchResult component.
Example usage without props (slot-scope is used to get the item in the SearchResult component):
```
<results-page>
  <div slot-scope="row">
    <search-result v-for="(content, index) in row">
      <template ...></template>
    </search-result>
  </div>
</results-page>
```
*/
export default class ResultsPage extends Vue {
  @State('currentScreenWidth') screenWidth: number

  // h1 title
  @Prop()
  title: string

  // Results type to display how many results are found (e.g. 9 job ads)
  @Prop()
  resultType: string

  // If a pagination should be shown
  @Prop()
  pagination: boolean

  // How many results per column are displayed, must be the same in the included SearchResult component
  @Prop({ default: 1, validator: function (value) { return value >= 1 && value <= 4 } })
  maxPerColumn: number

  // The items to display
  @Prop()
  items: any[]

  @Watch('items')
  onValueChange (newValue: any[]): void {
    this.filteredItems = newValue
  }

  // Items per page (only with pagination)
  @Prop()
  itemsPerPage: number

  // If provided, sorting controls are shown (includes key, label, isDate)
  @Prop()
  sortable: SortingInfo[]

  // Displays a filtering input field if set. Takes the keys which can be filtered on.
  @Prop()
  filter: FilteringType[]

  // Default is the normal searchResult, another type is used for the matching search results in the job finder
  @Prop({ default: 'searchResult' })
  searchResultDisplayType: string

  // If a top margin should be set for layout reasons
  @Prop({ default: true })
  marginTop: boolean

  // Change search result background-color on hover
  @Prop({ default: true })
  hover: boolean

  // Current page for the pagination
  @Prop()
  page: number

  @Watch('page')
  onPageChanged (newValue: number): void {
    this.currentPage = newValue
  }

  get perPage (): number {
    return this.itemsPerPage ? this.itemsPerPage : this.perColumn > 1 ? this.perColumn * 4 : 10
  }

  get totalRows (): number {
    return this.filteredItems.length
  }

  get perColumn (): number {
    if (this.screenWidth < bootstrapBreakPoints.md) return 1
    else if (this.screenWidth < bootstrapBreakPoints.lg) return Math.min(this.maxPerColumn, 2)
    else if (this.screenWidth < bootstrapBreakPoints.xl) return Math.min(this.maxPerColumn, 3)
    else return this.maxPerColumn
  }

  get md (): boolean {
    return this.screenWidth > bootstrapBreakPoints.md
  }

  get filterPlaceholder (): string {
    if (this.filter) {
      const labels = this.filter.map(el => el.label).join(', ')
      return this.$i18n.t('actions.filter', { what: labels }).toString()
    } else {
      return ''
    }
  }

  filterResults (e: Event): void {
    this.filteredItems = this.items.filter(item => {
      let matched = false
      this.filter.forEach(function (filterKey: FilteringType) {
        if (item[filterKey.key].toLowerCase().indexOf(e.toString().toLowerCase()) !== -1) matched = true
      })
      return matched
    })
  }

  filteredItems: any[] = []

  currentPage = 1

  sort (array: any[], key: string, isDate = false): void {
    if (this.sortingMetadata.key === key) {
      this.sortingMetadata.desc = !this.sortingMetadata.desc
    } else {
      this.sortingMetadata.key = key
      this.sortingMetadata.desc = false
    }
    sortArray(array, key, this.sortingMetadata.desc, isDate)
  }

  sortingMetadata: { key: string; desc: boolean } = { key: '', desc: false }

  created () {
    this.currentPage = this.page || 1
    this.filteredItems = this.items
    this.sortable.map(el => el.sort ? this.sort(this.filteredItems, el.key) : false)
  }
}
